<template>
  <div class="materialAccount-box">
    <el-collapse v-model="activeTitle" class="collapse-box">
      <el-collapse-item title="仪器耗材概述" name="showTitleData">
        <!-- 概述详情 -->
        <div class="materialDetailBox" v-loading="loadData">
          <el-tooltip
            class="item"
            effect="dark"
            content="库存种类数量与仪器耗材标准库种类数量之比"
            placement="top"
          >
            <div class="materialDetailBox-item">
              <div class="item-one">
                <div>设备耗材配齐率</div>
                <div class="item-one-img">
                  <img src="@/assets/noticePic.svg" />
                </div>
              </div>
              <div class="item-two">
                {{ materialSummary.materialRate * 100 }}%
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="统计库存信息中'是否危化品'为'是'的记录条数"
            placement="top"
          >
            <div class="materialDetailBox-item">
              <div class="item-one">
                <div>危化品数</div>
                <div class="item-one-img">
                  <img src="@/assets/noticePic.svg" />
                </div>
              </div>
              <div class="item-two">{{ materialSummary.dangerCount || 0 }}</div>
            </div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="报损工单数量与仪器耗材库存信息记录数（非库存数量）之比"
            placement="top"
          >
            <div class="materialDetailBox-item">
              <div class="item-one">
                <div>报损率</div>
                <div class="item-one-img">
                  <img src="@/assets/noticePic.svg" />
                </div>
              </div>
              <div class="item-two">{{ materialSummary.lossRate * 100 }}%</div>
            </div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="报失工单数量与仪器耗材库存信息记录数（非库存数量）之比"
            placement="top"
          >
            <div class="materialDetailBox-item">
              <div class="item-one">
                <div>报失率</div>
                <div class="item-one-img">
                  <img src="@/assets/noticePic.svg" />
                </div>
              </div>
              <div class="item-two">
                {{ materialSummary.brokenRate * 100 }}%
              </div>
            </div>
          </el-tooltip>
        </div>
      </el-collapse-item>
    </el-collapse>

    <el-row
      style="
        padding-bottom: 16px;
        font-size: 18px;
        font-weight: 700;
        border-bottom: 1px solid #eee;
        margin-top: 20px;
      "
      >仪器耗材库存信息</el-row
    >
    <el-row style="margin-top: 20px">
      <el-button v-throttle icon="el-icon-upload">导入</el-button>
      <el-button v-throttle icon="el-icon-download">导出</el-button>
      <el-button
        @click="handleMaterialRegisterBtnClick"
        v-throttle
        type="primary"
        icon="el-icon-circle-plus-outline"
        >仪器耗材登记</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline :model="materialAccountCondition">
        <el-form-item
          class="formItemBoxStyle"
          label="编号/名称"
          prop="materialCodeName"
        >
          <el-input
            style="width: 200px"
            v-model="materialAccountCondition.materialCodeName"
            placeholder="请输入"
            @blur="handleQueryMaterialAccount"
          />
        </el-form-item>
        <el-form-item
          class="formItemBoxStyle"
          label="存放位置"
          prop="sysOrgSchoolRoomId"
        >
          <el-select
            style="width: 200px"
            v-model="materialAccountCondition.sysOrgSchoolRoomId"
            placeholder="请选择"
            @change="handleSelectPlaceRoom"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolRoomList"
              :key="item.sysOrgSchoolRoomId"
              :label="item.name"
              :value="item.sysOrgSchoolRoomId"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 危化品 -->
        <el-form-item class="formItemBoxStyle" label="是否危化品">
          <el-select
            @change="handleQueryMaterialAccount"
            v-model="materialAccountCondition.hasDanger"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <!-- 消耗品 -->
        <el-form-item class="formItemBoxStyle" label="是否消耗品">
          <el-select
            @change="handleQueryMaterialAccount"
            v-model="materialAccountCondition.hasConsumables"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <!-- 是否达标 -->
        <el-form-item class="formItemBoxStyle" label="是否达标">
          <el-select
            @change="handleQueryMaterialAccount"
            v-model="materialAccountCondition.reachStandard"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="达标" :value="1"></el-option>
            <el-option label="未达标" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="formItemBoxStyle" label="配备要求">
          <el-select
            style="width: 200px"
            v-model="materialAccountCondition.equipRequire"
            @change="handleQueryMaterialAccount"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="基本" :value="1"></el-option>
            <el-option label="选配" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!-- 按钮区域 -->
          <el-button v-throttle @click="handleResetMaterialAccount"
            >重置</el-button
          >
          <el-button
            type="primary"
            v-throttle
            @click="handleQueryMaterialAccount"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格区域 -->
    <el-row>
      <el-table
       v-loading="loadTable"
        :data="materialTableList"
        ref="materialTableList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        class="accountTableBox"
        @expand-change="handleTableExpand"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div
              class="placeRoomBox"
              v-if="!roomData[scope.row.sysExpConsumeMaterialId]"
            >
              加载中...
            </div>
            <div
              class="placeRoomBox"
              v-else-if="
                roomData[scope.row.sysExpConsumeMaterialId].length == 0
              "
            >
              暂无存放信息
            </div>
            <div
              class="placeRoomBox"
              v-for="(item, i) in roomData[scope.row.sysExpConsumeMaterialId]"
              :key="i"
            >
              <div style="position: absolute; left: 120px; top: 0">
                存放位置：{{ item.sysOrgSchoolRoomName }}
              </div>
              <div style="position: absolute; left: 520px; top: 0">
                库存数量：{{ item.stockNum }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, i) in checkedTableHeaders"
          :key="item"
          :label="item"
          :prop="tablePropList[i]"
          :sortable="needSortHeader.indexOf(item) > -1"
        >
          <template slot-scope="scoped">
            <span v-if="item == '编号'">{{ scoped.row.internationCode }}</span>
            <span
              v-else-if="item == '名称'"
              style="cursor: pointer; color: #00f; text-decoration: underline"
              @click="handleCheckMaterialStockInfo(scoped.row)"
              >{{ scoped.row.materialName }}</span
            >
            <span v-else-if="item == '规格型号功能'">
              {{ scoped.row.materialSpecifications }}
            </span>
            <span v-else-if="item == '单位'">
              {{ scoped.row.materialUnit }}
            </span>
            <span v-else-if="item == '参考单价'">
              {{ scoped.row.materialUnitPrice }}
            </span>
            <span v-else-if="item == '标准数量'">
              {{ scoped.row.materialMinStandardNum }}~{{
                scoped.row.materialMaxStandardNum
              }}
            </span>
            <span
              v-else-if="item == '库存数量'"
              @click="handleCheckStockDetailInfo(scoped.row)"
              style="cursor: pointer; color: #00f; text-decoration: underline"
            >
              {{ scoped.row.stockNum ? scoped.row.stockNum : 0 }}
            </span>
            <span v-else-if="item == '配备要求'">
              {{
                scoped.row.equipRequire === 1
                  ? "基本"
                  : scoped.row.equipRequire === 2
                  ? "选配"
                  : "/"
              }}
            </span>
            <span v-else-if="item == '是否达标'">
              {{
                scoped.row.stockNum > scoped.row.materialMinStandardNum
                  ? "是"
                  : "否"
              }}
            </span>
            <span v-else-if="item == '执行标准'">
              {{ scoped.row.materialExecuteCode }}
            </span>
            <span v-else-if="item == '是否危化品'">
              {{ scoped.row.materialHasDanger ? "是" : "否" }}
            </span>
            <span v-else-if="item == '是否消耗品'">
              {{ scoped.row.materialHasConsumables ? "是" : "否" }}
            </span>
            <!-- <span v-else-if="item == '存放位置'">
              {{ scoped.row.sysOrgSchoolRoomName }}
            </span> -->
          </template>
        </el-table-column>
        <el-table-column center>
          <template slot="header">
            <span style="cursor: pointer" @click="handleSelectTableHeader">
              操作 <i class="el-icon-s-tools"></i>
            </span>
          </template>
          <template slot-scope="scoped">
            <!-- 停用状态 -->
            <span
              style="
                margin-right: 10px;
                cursor: pointer;
                color: blue;
                text-decoration: underline;
              "
              @click="handleEditMaterial(scoped.row)"
            >
              编辑
            </span>
            <span
              @click="handleDeleteMaterial(scoped.row)"
              style="cursor: pointer; color: red; text-decoration: underline"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px; margin-bottom: 20px">
      <el-pagination
        @size-change="handleSizeChangeMaterialMsgPage"
        @current-change="handleCurrentChangeMaterialMsgPage"
        :current-page="materialMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="materialMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="materialMsgPage.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 仪器耗材登记弹框 -->
    <el-dialog
      :title="isRegisterOrEdit == 1 ? '仪器耗材登记' : '仪器耗材编辑'"
      :visible.sync="registerOrEditMaterialDialogVisible"
      top="3%"
    >
      <div style="height: 750px; overflow: hidden; overflow-y: scroll">
        <div class="dialogTitle">
          <span>基础信息</span>
        </div>
        <div class="form-container">
          <el-form
            :model="registerOrEditForm"
            ref="registerOrEditForm"
            label-width="100px"
          >
            <el-form-item class="formItem redStar" label="国标编号：">
              <div
                style="
                  width: 450px;
                  padding-left: 14px;
                  height: 36px;
                  line-height: 36px;
                  border: 1px solid rgb(220, 223, 230);
                  border-radius: 5px;
                  position: relative;
                "
              >
                <span v-if="!internationCode" style="color: rgb(192, 196, 204)"
                  >请点击选择按钮进行选择</span
                >
                <span v-else>{{ internationCode }}</span>
                <div
                  style="
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    color: #00f;
                    text-decoration: underline;
                  "
                  @click="handleOpenAddMaterialDialog"
                >
                  选择
                </div>
              </div>
            </el-form-item>
            <el-form-item
              class="formItem redStar"
              label="名称："
              prop="materialName"
            >
              <el-input
                style="width: 466px"
                v-model.trim="registerOrEditForm.materialName"
                disabled
                onkeyup="this.value=this.value.replace(/\s/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="formItem redStar"
              label-width="120px"
              style="margin-left: -19px"
              label="是否危化品："
              prop="materialHasDanger"
            >
              <el-input
                disabled
                style="width: 466px"
                v-model.trim="registerOrEditForm.materialHasDanger"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="formItem redStar"
              label-width="120px"
              style="margin-left: -19px"
              label="是否消耗品："
              prop="materialHasConsumables"
            >
              <el-input
                disabled
                style="width: 466px"
                v-model.trim="registerOrEditForm.materialHasConsumables"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="formItem redStar"
              label-width="140px"
              style="margin-left: -39px"
              label="规格型号功能："
              prop="materialSpecifications"
            >
              <el-input
                disabled
                style="width: 466px"
                v-model.trim="registerOrEditForm.materialSpecifications"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="formItem redStar"
              label="单位："
              prop="materialUnit"
            >
              <el-input
                style="width: 466px"
                disabled
                v-model.trim="registerOrEditForm.materialUnit"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="formItem redStar"
              label="参考单价："
              prop="materialUnitPrice"
            >
              <el-input
                style="width: 466px"
                disabled
                v-model.trim="registerOrEditForm.materialUnitPrice"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="formItem redStar"
              label="执行标准："
              prop="materialExecuteCode"
            >
              <el-input
                disabled
                style="width: 466px"
                v-model.trim="registerOrEditForm.materialExecuteCode"
              ></el-input>
            </el-form-item>
            <el-form-item class="formItem redStar" label="图片：">
              <div>
                <el-upload
                  ref="pictureUpload"
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                  accept=".jpg, .png"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <div>
                  <div v-if="this.editDialogImageList.length > 0">
                    -- 已上传的图片 --
                  </div>
                  <el-image
                    v-for="(item, i) in this.editDialogImageList"
                    :key="i"
                    style="width: 150px; height: 150px; margin-right: 10px"
                    :src="item"
                    fit="cover"
                  ></el-image>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="text-align: right; margin-top: 20px">
        <el-button
          @click="
            () => {
              registerOrEditMaterialDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handleConfirmAddOrEditStockBtnClick"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <!-- 图片预览弹框 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- 点击添加仪器耗材弹框 -->
    <el-dialog
      width="1200px"
      title="添加仪器耗材"
      :visible.sync="addMaterialDialogVisible"
    >
      <el-form inline :model="addMaterialFormCondition">
        <el-form-item label="编号" prop="materialNameOrCode">
          <el-input
            v-model="addMaterialFormCondition.internationCode"
            placeholder="请输入"
            style="width: 160px"
            @input="handleQueryAddMaterialTable"
          ></el-input>
        </el-form-item>
        <el-form-item :resizable="false" label="名称" prop="materialName">
          <el-input
            v-model="addMaterialFormCondition.materialName"
            placeholder="请输入"
            style="width: 160px"
            @input="handleQueryAddMaterialTable"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否危化品"
          prop="materialHasDanger"
          class="formItemBoxStyle"
        >
          <el-select
            v-model="addMaterialFormCondition.materialHasDanger"
            placeholder="请输入"
            style="width: 160px"
            @change="handleQueryAddMaterialTable"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="是否消耗品"
          prop="materialHasConsumables"
          class="formItemBoxStyle"
        >
          <el-select
            v-model="addMaterialFormCondition.materialHasConsumables"
            placeholder="请输入"
            style="width: 160px"
            @change="handleQueryAddMaterialTable"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetAddMaterialTable">重置</el-button>
          <el-button @click="handleQueryAddMaterialTable" type="primary"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-row>
        <div style="position: relative">
          <el-table
            border
            :data="addMaterialDataList"
            ref="addMaterialDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @row-click="handleRowClickToAddMaterial"
            @select="handleSelectionChange"
          >
            <el-table-column
              prop="internationCode"
              label="国标编号"
              center
              :resizable="false"
            ></el-table-column>
            <el-table-column prop="materialName" label="名称" center>
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="materialHasDanger"
              label="是否危化品"
              center
            >
              <template slot-scope="scoped">
                {{ scoped.row.materialHasDanger ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="materialHasConsumables"
              label="是否消耗品"
              :resizable="false"
              center
            >
              <template slot-scope="scoped">
                {{ scoped.row.materialHasConsumables ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="materialSpecifications"
              label="规格型号功能"
              center
            ></el-table-column>
            <el-table-column :resizable="false" label="标准数量" center>
              <template slot-scope="scoped">
                {{ scoped.row.materialMinStandardNum }} ~
                {{ scoped.row.materialMaxStandardNum }}
              </template>
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="materialUnit"
              label="单位"
              center
            ></el-table-column>
            <el-table-column
              :resizable="false"
              center
              width="50"
              type="selection"
            >
            </el-table-column>
          </el-table>
          <div
            style="
              width: 40px;
              height: 40px;
              position: absolute;
              top: 0;
              right: 0;
              background-color: #dfe6ec;
              z-index: 999;
            "
          ></div>
        </div>
      </el-row>
      <!-- 分页组件 -->
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChangeAddMaterialTableMsg"
          @current-change="handleCurrentChangeAddMaterialTableMsg"
          :current-page="addMaterialTableMsg.pageIndex"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="addMaterialTableMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="addMaterialTableMsg.pageTotal"
        ></el-pagination>
      </el-row>
      <div style="margin-top: 20px; text-align: right">
        <el-button @click="handleCancelAddMaterial">取消</el-button>
        <el-button type="primary" @click="handleConfirmAddMaterial"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看实验设备详情弹框 -->
    <el-dialog
      title="查看实验设备详情"
      :visible.sync="checkMaterialStockInfoDialogVisible"
    >
      <checkMaterialCom
        :stockInfo="materialStockInfo"
        :imgUrl="editDialogImageList"
      />
    </el-dialog>
    <!-- 查看库存详情弹框 -->
    <el-dialog
      title="库存明细"
      :visible.sync="checkStockDetailInfoDialogVisible"
      top="5%"
      width="1000px"
    >
      <el-table
        :data="stockDetailDataList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        height="600"
      >
        <el-table-column
          :formatter="customFormatter"
          label="日期"
          prop="operateTime"
          width="200"
        ></el-table-column>
        <el-table-column
          :formatter="customFormatter"
          label="出入库类型"
          prop="stockInOutType"
          width="100"
        ></el-table-column>
        <el-table-column
          :formatter="customFormatter"
          label="出入库分类"
          prop="operateType"
          width="100"
        ></el-table-column>
        <el-table-column
          label="出入库数量"
          prop="operateNum"
          :formatter="customFormatter"
          width="100"
        ></el-table-column>
        <el-table-column
          label="库存变化"
          prop="stockNumChange"
          :formatter="customFormatter"
          width="100"
        >
        </el-table-column>
        <el-table-column
          label="原因"
          prop="reason"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="操作人"
          prop="operateUserName"
          :formatter="customFormatter"
          width="120"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 设置表格列弹框 -->
    <el-dialog
      :visible.sync="setTableHeaderDialogVisible"
      title="请选择列表展示字段"
      width="600px"
      top="15%"
    >
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkTableHeaderAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0"></div>
      <el-checkbox-group
        v-model="checkedTableHeaders"
        @change="handleCheckedTableHeaderChange"
      >
        <el-checkbox
          style="margin: 10px"
          v-for="item in tableHeaders"
          :label="item"
          :key="item"
          >{{ item }}</el-checkbox
        >
      </el-checkbox-group>
      <div style="text-align: center; margin-top: 20px">
        <el-button
          @click="
            () => {
              setTableHeaderDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handleSaveTableHeaderBtn"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterialListApi,
  uploadMaterialPicApi,
} from "@/api/standard/standardDeviceLib.js";
import { getDicFormInfoApi } from "@/api/standard/dictionaryForm.js";
import {
  getMaterialAccountInfoApi,
  addStockEquipmentApi,
  deleteStockEquipmentApi,
  getStockEquipmentDetailApi,
  getMaterialBaseInfoApi,
  updateStockEquipmentApi,
  getMaterialSummaryApi,
  getMaterialInOutStockRecordApi,
  getMaterialLocationApi,
} from "@/api/materialManage/materialAccount.js";
// import { getOutInLibListInfoApi } from "@/api/materialManage/outInLib.js";
import { getAllSchoolRoomListApi } from "@/api/mechanism/schoolRoomManage.js";
import { isEmpty, formatDate } from "@/utils/util";
import checkMaterialCom from "../components/checkMaterialCom.vue";
import { getStore } from "@/utils/token.js";
export default {
  name: "materialAccount",
  components: {
    checkMaterialCom,
  },
  data() {
    return {
      isRegisterOrEdit: 1, // 1 登记 0 编辑
      materialAccountCondition: {
        materialCodeName: "",
        equipRequire: "",
        hasDanger: "",
        hasConsumables: "",
        reachStandard: "",
        sysOrgSchoolRoomId: "",
        sysOrgSchoolId: 0,
      },
      materialTableList: [],
      materialMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      registerOrEditMaterialDialogVisible: false,
      registerOrEditForm: {
        materialName: "",
        materialHasDanger: "",
        materialHasConsumables: "",
        materialSpecifications: "",
        materialUnit: "",
        materialUnitPrice: "",
        materialExecuteCode: "",
      },
      dialogImageUrl: "",
      dialogVisible: false,
      editDialogImageList: [], // 用于展示已上传的图片
      imageUrlList: [], // 用于发送上传请求后收集图片路径
      materialImgUrl: [], // 用于收集添加耗材后的图片路劲
      disabled: false,
      addMaterialDialogVisible: false,
      addMaterialFormCondition: {
        internationCode: "",
        materialName: "",
        materialHasConsumables: "",
        materialHasDanger: "",
      },
      addMaterialDataList: [],
      addMaterialTableMsg: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      subjectList: [],
      selectedRow: null,
      internationCode: "",
      addFunctionRoomDialogVisible: false,
      addFunctionRoomFormCondition: {
        functionRoomCodeName: "",
        subjectDicId: "",
      },
      functionRoomDataList: [],
      addFunctionRoomTableMsg: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      functionRoomAddress: "",
      functionRoomSelectedId: "",
      functionRoomSelectedRow: "",
      materialId: "",
      checkMaterialStockInfoDialogVisible: false,
      materialStockInfo: {
        affiliatedUnit: "",
        internationCode: "",
        materialHasDanger: "",
        materialHasConsumables: "",
        materialSpecifications: "",
        subjectDicId: "",
        materialUnitPrice: "",
        equipRequir: "",
        keeper: "",
        placeRoomId: "",
        snCode: "",
        materialFeature: "", // 物品特征
      },
      checkStockDetailInfoDialogVisible: false,
      stockDetailDataList: [],
      activeTitle: ["showTitleData"],
      setTableHeaderDialogVisible: false,
      isIndeterminate: false,
      checkTableHeaderAll: true,
      tableHeaderOptions: [
        "编号",
        "名称",
        "规格型号功能",
        "单位",
        "参考单价",
        "标准数量",
        "库存数量",
        "配备要求",
        "是否达标",
        "执行标准",
        "是否危化品",
        "是否消耗品",
      ],
      checkedTableHeaders: [
        "编号",
        "名称",
        "规格型号功能",
        "单位",
        "参考单价",
        "标准数量",
        "库存数量",
        "配备要求",
        "是否达标",
        "执行标准",
        "是否危化品",
        "是否消耗品",
      ],
      tableHeaders: [
        "编号",
        "名称",
        "规格型号功能",
        "单位",
        "参考单价",
        "标准数量",
        "库存数量",
        "配备要求",
        "是否达标",
        "执行标准",
        "是否危化品",
        "是否消耗品",
      ],
      tempTableHeader: [],
      needSortHeader: [
        "库存数量",
        "配备要求",
        "是否达标",
        "是否危化品",
        "是否消耗品",
      ],
      tablePropList: [
        "internationCode",
        "materialName",
        "materialSpecifications",
        "materialUnit",
        "materialUnitPrice",
        "standardNumber",
        "stockNum",
        "equipRequire",
        "reachStandard",
        "materialExecuteCode",
        "materialHasDanger",
        "materialHasConsumables",
        "roomName",
      ],
      isSaveTable: false,
      storeInfoData: [
        {
          sysOrgSchoolRoomName: "",
          placeRoomId: "",
          snCode: "",
          stockNum: "",
          keeper: "",
        },
      ],
      eqptStockCategoryId: "",
      sysExpConsumeMaterialId: "",
      schoolRoomList: [],

      materialSummary: {
        materialRate: 0,
        dangerCount: 0,
        lossRate: 0,
        brokenRate: 0,
      },
      roomData: {},

      sysOrgSchoolId: 0,

      loadTable: true,
      loadData: true
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"));
    this.materialAccountCondition.sysOrgSchoolId = this.sysOrgSchoolId;
    this.fetchAddMaterialTableData();
    this.fetchSubjectInfo();
    this.fetchMaterialAccountInfo();
    this.getAllSchoolRoomListApi();
    this.fetchMaterialSummary();
  },
  watch: {
    setTableHeaderDialogVisible(val) {
      if (!val && !this.isSaveTable) {
        this.checkedTableHeaders = [...this.tempTableHeader];
      } else {
        this.isSaveTable = false;
      }
    },
  },
  methods: {
    // 获取耗材概况
    fetchMaterialSummary() {
      getMaterialSummaryApi(
        this.sysOrgSchoolId === null ? 0 : this.sysOrgSchoolId
      ).then((res) => {
        if (res.success) {
          // console.log(res)
          this.materialSummary = res.data;
          this.loadData = false
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 分页获取台账
    fetchMaterialAccountInfo() {
      getMaterialAccountInfoApi(
        this.materialMsgPage.pageIndex,
        this.materialMsgPage.pageSize,
        this.materialAccountCondition
      ).then((res) => {
        if (res.success) {
          this.materialTableList = res.data;
          this.materialMsgPage.pageTotal = res.total;
          this.loadTable = false
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取科目以及学段信息
    fetchSubjectInfo() {
      getDicFormInfoApi(4).then((res) => {
        if (res.success) {
          this.subjectList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取添加仪器耗材表格数据
    fetchAddMaterialTableData() {
      getMaterialListApi(
        this.addMaterialTableMsg.pageIndex,
        this.addMaterialTableMsg.pageSize,
        this.addMaterialFormCondition
      ).then((res) => {
        if (res.success) {
          this.addMaterialDataList = res.data;
          // for (let i = 0; i < this.addMaterialDataList.length; i++) {
          //   this.$set(this.addMaterialDataList[i], "selected", false);
          // }
          this.addMaterialTableMsg.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取全部功能室
    getAllSchoolRoomListApi() {
      getAllSchoolRoomListApi(this.sysOrgSchoolId).then((res) => {
        if (res.success) {
          this.schoolRoomList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加仪器耗材
    handleMaterialRegisterBtnClick() {
      this.isRegisterOrEdit = 1;
      this.registerOrEditForm = {
        materialName: "",
        materialHasDanger: "",
        materialHasConsumables: "",
        materialSpecifications: "",
        materialUnit: "",
        materialUnitPrice: "",
        materialExecuteCode: "",
      };
      this.editDialogImageList = [];
      this.internationCode = "";
      this.$refs.pictureUpload && (this.$refs.pictureUpload.uploadFiles = []);
      this.registerOrEditMaterialDialogVisible = true;
    },
    // 编辑仪器耗材
    handleEditMaterial(row) {
      this.eqptStockCategoryId = row.stockCategoryId;
      this.sysExpConsumeMaterialId = row.sysExpConsumeMaterialId;
      this.imageUrlList = [];
      this.materialImgUrl = [];
      this.editDialogImageList = [];
      this.$refs.pictureUpload && (this.$refs.pictureUpload.uploadFiles = []);
      let fd = new FormData();
      fd.append("sysExpConsumeMaterialId", row.sysExpConsumeMaterialId);
      fd.append("sysOrgSchoolId", this.sysOrgSchoolId);
      fd.append(
        "sysOrgSchoolRoomId",
        row.sysOrgSchoolRoomId ? row.sysOrgSchoolRoomId : 0
      );
      getStockEquipmentDetailApi(fd).then((res) => {
        if (res.success) {
          const { data } = res;
          this.registerOrEditForm = {
            materialName: data.materialName,
            materialHasDanger: data.materialHasDanger ? "是" : "否",
            materialHasConsumables: data.materialHasConsumables ? "是" : "否",
            materialSpecifications: data.materialSpecifications,
            materialUnit: data.materialUnit,
            materialUnitPrice: data.materialUnitPrice,
            materialExecuteCode: data.materialExecuteCode,
            sysOrgSchoolId: this.sysOrgSchoolId,
          };
          this.internationCode = data.internationCode;
          data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          this.isRegisterOrEdit = 0;
          this.registerOrEditMaterialDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 确认登记或编辑仪器
    async handleConfirmAddOrEditStockBtnClick() {
      if (this.internationCode == "") {
        this.$message.warning("请选择仪器耗材!");
        return;
      }
      // 校验结束，根据新增或编辑发送请求
      if (this.isRegisterOrEdit == 1) {
        // 这里发送添加请求
        // 如果有传图片，先调用图片上传接口获取 imgUrl
        let imgUrl = [];
        if (this.$refs.pictureUpload.uploadFiles.length > 0) {
          await this.getUploadImgUrl();
          if (!this.imageUrlList.length) {
            return;
          }
          imgUrl = this.imageUrlList;
        } else {
          imgUrl = this.materialImgUrl;
        }
        let addParams = {
          sysExpConsumeMaterialId: this.sysExpConsumeMaterialId,
          imgUrl,
          sysOrgSchoolId: this.sysOrgSchoolId,
        };
        addStockEquipmentApi(addParams).then((res) => {
          if (res.success) {
            this.$message.success("登记成功!");
            this.fetchMaterialAccountInfo();
            this.registerOrEditMaterialDialogVisible = false;
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      } else {
        // 这里发送编辑请求
        // 如果有传图片，先调用图片上传接口获取 imgUrl
        let imgUrl = [];
        if (this.$refs.pictureUpload.uploadFiles.length > 0) {
          // 如果有上传图片，已上传图片为主
          await this.getUploadImgUrl();
          if (!this.imageUrlList.length) {
            return;
          }
          imgUrl = this.imageUrlList;
        } else if (this.materialImgUrl.length > 0) {
          // 如果无上传图片，但添加了新的耗材，以该耗材图片为主
          imgUrl = this.materialImgUrl;
        } else {
          // 既没上传图片，也没点击添加按钮，则图片不变；
          imgUrl = this.registerOrEditForm.imgUrl;
        }
        let editParams = {
          eqptStockCategoryId: this.eqptStockCategoryId,
          sysExpConsumeMaterialId: this.sysExpConsumeMaterialId,
          imgUrl,
        };
        updateStockEquipmentApi(editParams).then((res) => {
          if (res.success) {
            this.$message.success("修改成功!");
            this.fetchMaterialAccountInfo();
            this.registerOrEditMaterialDialogVisible = false;
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      }
    },
    // 删除仪器耗材
    handleDeleteMaterial(row) {
      this.$confirm(`确定删除该仪器耗材吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 这里调用接口删除
          deleteStockEquipmentApi(row.stockCategoryId).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.fetchMaterialAccountInfo();
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消删除`,
          });
        });
    },
    // 确认添加仪器耗材
    handleConfirmAddMaterial() {
      // 给表格填充数据
      console.log(this.selectedRow);
      this.registerOrEditForm = {
        internationCode: this.selectedRow.internationCode,
        materialName: this.selectedRow.materialName,
        materialHasDanger: this.selectedRow.materialHasDanger ? "是" : "否",
        materialHasConsumables: this.selectedRow.materialHasConsumables
          ? "是"
          : "否",
        materialSpecifications: this.selectedRow.materialSpecifications,
        // subjectDicId: "",
        materialUnit: this.selectedRow.materialUnit,
        materialUnitPrice: this.selectedRow.materialUnitPrice,
        standardNumber:
          this.selectedRow.materialMinStandardNum +
          "~" +
          this.selectedRow.materialMaxStandardNum,
        // equipRequire: "",
        materialExecuteCode: this.selectedRow.materialExecuteCode,
      };
      this.materialImgUrl = this.selectedRow.imgUrl;
      this.selectedRow.imgUrl?.forEach((item) => {
        this.editDialogImageList.push(this.$imageAddress(item));
      });
      this.internationCode = this.selectedRow.internationCode;
      this.sysExpConsumeMaterialId = this.selectedRow.materialId;
      this.addMaterialDialogVisible = false;
    },
    // 取消添加
    handleCancelAddMaterial() {
      this.addMaterialDialogVisible = false;
    },
    // 打开添加仪器耗材弹框
    handleOpenAddMaterialDialog() {
      this.editDialogImageList = [];
      this.addMaterialDialogVisible = true;
    },
    // 重置添加耗材表格
    handleResetAddMaterialTable() {
      this.addMaterialFormCondition = {
        internationCode: "",
        materialName: "",
        materialHasConsumables: "",
        materialHasDanger: "",
      };
      this.handleQueryAddMaterialTable();
    },
    // 查询添加耗材表格
    handleQueryAddMaterialTable() {
      this.addMaterialTableMsg.pageIndex = 1;
      this.addMaterialTableMsg.pageSize = 5;
      this.fetchAddMaterialTableData();
    },
    handleRowClickToAddMaterial(row, column, event) {
      this.handleSelectionChange("", row);
    },
    handleSelectionChange(rows, row) {
      // 清空所有选择
      this.$refs.addMaterialDataList.clearSelection();
      // 再根据selected的值进行目标选框状态确定
      this.addMaterialDataList.forEach((it) => {
        if (it.internationCode == row.internationCode) {
          // console.log(row.selected);
          if (row.selected) {
            it.selected = false;
            this.$refs.addMaterialDataList.toggleRowSelection(row, false);
          } else {
            it.selected = true;
            this.selectedRow = row;
            this.$refs.addMaterialDataList.toggleRowSelection(row, true);
          }
        } else {
          it.selected = false;
        }
      });
    },
    // 重置耗材总账表格
    handleResetMaterialAccount() {
      this.materialAccountCondition = {
        materialCodeName: "",
        subjectType: "",
        hasDanger: "",
        hasConsumables: "",
        reachStandard: "",
        sysOrgSchoolRoomId: "",
        sysOrgSchoolId:this.sysOrgSchoolId,
      };
      this.roomData = {};
      this.handleQueryMaterialAccount();
    },
    // 查询耗材总账表格
    handleQueryMaterialAccount() {
      this.materialMsgPage.pageIndex = 1;
      this.materialMsgPage.pageSize = 10;
      this.fetchMaterialAccountInfo();
    },
    // 仪器耗材登记表格页容量改变
    handleSizeChangeMaterialMsgPage(val) {
      this.materialMsgPage.pageIndex = 1;
      this.materialMsgPage.pageSize = val;
      this.fetchMaterialAccountInfo();
    },
    // 仪器耗材登记表格当前页改变
    handleCurrentChangeMaterialMsgPage(val) {
      this.materialMsgPage.pageIndex = val;
      this.fetchMaterialAccountInfo();
    },
    // 移除预览图片
    handleRemove(file) {
      let uploadFiles = this.$refs.pictureUpload.uploadFiles;
      for (let i = 0; i < uploadFiles.length; i++) {
        if (file.url === uploadFiles[i]["url"]) {
          uploadFiles.splice(i, 1);
        }
      }
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 这里调用图片接口上传图片
    async getUploadImgUrl() {
      let fd = new FormData();
      this.$refs.pictureUpload.uploadFiles.forEach((item) => {
        fd.append("images", item.raw);
      });
      const res = await uploadMaterialPicApi(fd);
      if (res.success) {
        this.imageUrlList = res.data;
      } else {
        if (res.msg === "上传文件格式不正确，请检查文件格式") {
          res.msg = "上传文件格式不正确，仅支持 .jpg，.png 格式图片";
        }
        this.$message.error(res.msg);
        return;
      }
    },
    // 添加仪器耗材表格改变当前页
    handleCurrentChangeAddMaterialTableMsg(val) {
      this.addMaterialTableMsg.pageIndex = val;
      this.fetchAddMaterialTableData();
    },
    // 添加仪器耗材表格改变当前页添加仪器耗材表格改变页容量
    handleSizeChangeAddMaterialTableMsg(val) {
      this.addMaterialTableMsg.pageIndex = 1;
      this.addMaterialTableMsg.pageSize = val;
      this.fetchAddMaterialTableData();
    },
    // 点击物品名称进行信息查看
    handleCheckMaterialStockInfo(row) {
      let fd = new FormData();
      fd.append("sysExpConsumeMaterialId", row.sysExpConsumeMaterialId);
      fd.append("sysOrgSchoolId", this.sysOrgSchoolId);
      // fd.append("roomId", row.roomId ? row.roomId : 0);
      getStockEquipmentDetailApi(fd).then((res) => {
        if (res.success) {
          this.editDialogImageList = [];
          const { data } = res;
          this.materialStockInfo = {
            materialName: data.materialName,
            affiliatedUnit: data.tenantId,
            internationCode: data.internationCode,
            materialHasDanger: data.materialHasDanger ? "是" : "否",
            materialHasConsumables: data.materialHasConsumables ? "是" : "否",
            materialSpecifications: data.materialSpecifications,
            subjectDicId: data.subjectTypeDicName,
            materialUnitPrice: data.materialUnitPrice,
            equipRequire: data.equipRequire == 1 ? "基本" : "选配",
            keeper: data.keeper,
            placeRoomId: data.roomName,
            snCode: data.snCode,
            materialFeature: data.materialItemTagDTOS, // 物品特征
          };
          data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          this.checkMaterialStockInfoDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 点击库存数量查看库存详情
    handleCheckStockDetailInfo(row) {
      // getOutInLibListInfoApi(1, 10, {
      //   materialCodeName: row.consumeMaterialDTO.internationCode,
      // }).then((res) => {
      //   if (res.success) {
      //     this.stockDetailDataList = res.data;
      //     this.checkStockDetailInfoDialogVisible = true;
      //   } else {
      //     this.$message.error(res.msg);
      //     return;
      //   }
      // });
      getMaterialInOutStockRecordApi(
        this.sysOrgSchoolId,
        row.sysExpConsumeMaterialId
      ).then((res) => {
        if (res.success) {
          this.stockDetailDataList = res.data;
          this.checkStockDetailInfoDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 全选
    handleCheckAllChange(val) {
      // console.log(this.tableHeaderOptions);
      this.checkedTableHeaders = val ? this.tableHeaderOptions : [];
      this.isIndeterminate = false;
    },
    // 单选
    handleCheckedTableHeaderChange(value) {
      let checkedCount = value.length;
      this.checkTableHeaderAll = checkedCount === this.tableHeaders.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableHeaders.length;
    },
    // 设置表格列按钮点击
    handleSelectTableHeader() {
      this.tempTableHeader = [...this.checkedTableHeaders];
      if (this.checkedTableHeaders.length === this.tableHeaderOptions.length) {
        this.isIndeterminate = false;
        this.checkTableHeaderAll = true;
      } else if (this.checkedTableHeaders.length > 0) {
        this.isIndeterminate = true;
      } else {
        this.checkTableHeaderAll = false;
        this.isIndeterminate = false;
      }
      this.setTableHeaderDialogVisible = true;
    },
    // 保存设置
    handleSaveTableHeaderBtn() {
      this.isSaveTable = true;
      this.setTableHeaderDialogVisible = false;
      // console.log(this.checkedTableHeaders);
    },
    // 添加存放位置
    handleAddStoreListBtnClick() {
      let name =
        this.storeInfoData[this.storeInfoData.length - 1].sysOrgSchoolRoomName;
      if (!name) {
        this.$message.warning("请填写存放信息!");
      } else {
        this.storeInfoData.push({
          sysOrgSchoolRoomName: "",
          placeRoomId: "",
          snCode: "",
          stockNum: "",
          keeper: "",
        });
      }
    },
    // 排序方法
    // handleTableSortFunc(a,b){
    //   console.log(a,b)
    // },
    //
    handleTableExpand(row, expandedRows) {
      if (this.roomData[row.sysExpConsumeMaterialId]) {
        return;
      }
      getMaterialLocationApi(
        row.sysExpConsumeMaterialId,
        this.materialAccountCondition
      ).then((res) => {
        if (res.success) {
          this.$set(this.roomData, row.sysExpConsumeMaterialId, res.data);
          this.$forceUpdate();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleSelectPlaceRoom() {
      // this.$refs.materialTableList.toggleAllSelection()  toggleRowExpansion
      this.materialTableList.forEach((item) => {
        this.$refs.materialTableList.toggleRowExpansion(item, false);
      });
      this.roomData = {};
      this.handleQueryMaterialAccount();
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "operateUserName":
          if (!row.operateUserName) {
            return "/";
          }
          return row.operateUserName;
        case "operateNum":
          if (isEmpty(row.operateNum)) {
            return "/";
          }
          return row.operateNum;
        case "stockNumChange":
          if (isEmpty(row.operateNum)) {
            return "/";
          }
          return row.stockInOutType
            ? `-${row.operateNum}`
            : `+${row.operateNum}`;
        case "operateTime":
          if (isEmpty(row.operateTime)) {
            return "/";
          }
          return formatDate(new Date(row.operateTime), "yyyy-MM-dd hh:mm:ss");
        case "stockInOutType":
          switch (row.stockInOutType) {
            case 1:
              return "出库";
            case 0:
              return "入库";
          }
        case "operateType":
          if (isEmpty(row.operateType)) {
            return "/";
          }
          switch (row.operateType) {
            case 101:
              return "采购";
            case 102:
              return "归还";
            case 103:
              return "其他";
            case 201:
              return "借用";
            case 202:
              return "报失";
            case 203:
              return "报损";
            case 204:
              return "正常使用";
            case 205:
              return "其他";
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.materialDetailBox {
  display: flex;
}
.materialDetailBox-item {
  margin-left: 48px;
  width: 160px;
}
.item-one {
  display: flex;
  justify-content: space-between;
}
.item-one-img {
  width: 22px;
  height: 22px;
  img {
    width: 22px;
    height: 22px;
  }
}
.item-two {
  font-size: 36px;
}
.dialogTitle {
  height: 25px;
  border-left: 4px solid #00f;
  line-height: 25px;
  margin-bottom: 10px;
  span {
    font-size: 18px;
    margin-left: 20px;
  }
}
.form-container {
  width: 85%;
  margin: 0 auto;
}
::v-deep .redStar {
  .el-form-item__label::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.redStar1::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
::v-deep .collapse-box {
  .el-collapse-item__header {
    padding-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
  }
}
.materialDetailItem {
  width: 50%;
  padding: 20px 0;
  text-align: center;
  &.itemFontWeight {
    font-weight: 700;
  }
}

.placeRoomBox {
  padding-left: 120px;
  height: 50px;
  line-height: 50px;
  position: relative;
  border-bottom: 1px solid rgb(235, 238, 245);
  &:last-child {
    border-bottom: none;
  }
}
::v-deep .accountTableBox {
  .el-table__cell.el-table__expanded-cell {
    padding: 0;
  }
}
</style>
