import { request, noTimeOutReq } from "@/utils/request.js";

// 分页查询展示所有【仪器耗材表信息】
export function getMaterialListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/experiment/consume/material/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 新增【仪器耗材数据】
export function addMaterialApi(data) {
  return request({
    url: `/sys/experiment/consume/material/add`,
    method: "post",
    data,
  });
}

// 删除【仪器耗材数据】
export function deleteMaterialApi(consumeMaterialId) {
  return request({
    url: `/sys/experiment/consume/material/delete`,
    method: "post",
    params:{
      consumeMaterialId
    }
  });
}

// 获取【仪器耗材数据】详情
export function getMaterialDetailApi(materialId) {
  return request({
    url: `/sys/experiment/consume/material/get`,
    method: "post",
    params:{
      materialId
    }
  });
}

// 修改【仪器耗材数据】
export function editMaterialApi(data) {
  return request({
    url: `/sys/experiment/consume/material/update`,
    method: "post",
    data,
  });
}

// 上传【仪器耗材图片】
export function uploadMaterialPicApi(data) {
  return request({
    url: `/sys/experiment/consume/material/upload`,
    method: "post",
    data,
  });
}

// 根据物品标签获取物品列表
export function getClassifyMaterialListApi(pageIndex, pageSize, itemTagId) {
  return request({
    url: `/sys/experiment/consume/material/page/${pageIndex}/${pageSize}/list/itemTagId`,
    method: "post",
    params:{
      itemTagId
    }
  });
}